import {
  combineReducers,
    configureStore,
  } from "@reduxjs/toolkit";
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER, } from 'redux-persist';
import providerReducer from './features/providerSlice';
import applicationSlice from './features/parentApplicationSlice';
import applicationsSlice from "./features/applicationsSlice";
import domainConfigSlice from "./features/domainConfigSlice";
  
// Combine reducers
const reducer = combineReducers({
  provider: providerReducer,
  application: applicationSlice,
  applications: applicationsSlice,
  domain: domainConfigSlice
});

//persistant setting
const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, reducer);

  // Create Store equivalent
export const store = configureStore({
  reducer: persistedReducer,
  // We don't use thunk
  //middleware: (getDefaultMiddleware) => [...getDefaultMiddleware(),]
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
  
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export const persistor = persistStore(store);
  