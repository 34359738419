import { Expose } from 'class-transformer';

export class Coordinates {
  constructor(obj: object = {}) {
    Object.assign(this, obj);
  }

  @Expose({ name: 'lat' })
  public lat: number = 0;

  @Expose({ name: 'lon' })
  public lon: number = 0;
}
