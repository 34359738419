import { Expose, Type } from 'class-transformer';
import { Coordinates } from './subObjects/Coodinates';
import { ProviderResult } from './subObjects/ProviderResult';

export class Providers {
  constructor(obj: object = {}) {
    Object.assign(this, obj);
  }

  // type: uuid
  @Expose({ name: 'network_id' })
  public networkId: string = '';

  @Expose({ name: 'address' })
  public address: string = '';

  @Type(() => Coordinates)
  @Expose({ name: 'coordinates' })
  public coordinates?: Coordinates = new Coordinates();

  @Type(() => ProviderResult)
  @Expose({ name: 'results' })
  public results?: Array<ProviderResult> = [new ProviderResult()]

}

export class SearchProviders {
  constructor(obj: object = {}) {
    Object.assign(this, obj);
  }

  // type: uuid
  @Expose({ name: 'network_id' })
  public networkId: string = '';

  @Expose({ name: 'address' })
  public address: string = '';

}
