import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IProviderData } from 'types/provider';
import { searchProviders } from 'api/providerSvc';

// Create Slice combine the actions, initialState, and reducer into 1 function
type IinitialState = {
  address: string;
  providers?: IProviderData[];
  loading: boolean;
  error?: null | string;
  providerById?: {};
};

const initialState: IinitialState = { address: '', providers: [], loading: false, providerById: {} };

export const fetchSearchProvider = createAsyncThunk(
  'get/searchProvider',
  async (data: { networkId: string; address: string }, { rejectWithValue }) => {
    try {
      //const responses = await axios.get("posts");
      const response = await searchProviders(data);

      return JSON.stringify({ providers: response.results, address: response.address });
    } catch (err: any) {
      return rejectWithValue({ error: err.message });
    }
  }
);

const providerSlice = createSlice({
  name: 'providers',
  initialState: initialState,
  reducers: {
    searchProvider: (state, action: PayloadAction<IinitialState>) => {
      state = { ...state, address: action.payload.address, providers: [] };
      //state.push({address: action.payload.address})
      //state.address = action.payload.address;
      //state.providers = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSearchProvider.fulfilled, (state, action) => {
      // both `state` and `action` are now correctly typed
      // based on the slice state and the `pending` action creator
      const providerData = JSON.parse(action.payload);
      const providerById = {};
      providerData.providers.forEach((p: any) => {
        Object.assign(providerById, { [p.provider.id]: { name: p.provider.name } });
      });
      state.providerById = providerById;
      state.providers = providerData.providers;
      state.address = providerData.address;
      state.loading = false;
    });
    builder.addCase(fetchSearchProvider.pending, (state, action) => {
      // both `state` and `action` are now correctly typed
      // based on the slice state and the `pending` action creator
      state.providers = [];
      state.loading = true;
    });
    builder.addCase(fetchSearchProvider.rejected, (state, action) => {
      // both `state` and `action` are now correctly typed
      // based on the slice state and the `pending` action creator
      state.error = action.error.message;
      state.loading = false;
    });
  },
});

// Export actions
export const { searchProvider } = providerSlice.actions;

// Export reducer
export default providerSlice.reducer;
