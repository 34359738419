import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchApplicationsList } from 'api/applicationSvc';
import { IApplicationsDataGridList } from 'types/application';

// Create Slice combine the actions, initialState, and reducer into 1 function
type IinitialState = {
  applications?: IApplicationsDataGridList[];
  loading: boolean;
  error?: null | string;
};

const initialState: IinitialState = {
  applications: [],
  loading: false,
};

export const fetchApplicationsListAction = createAsyncThunk(
  'get/fetchApplicationsList',
  async (networkId: string, { rejectWithValue }) => {
    try {
      const response = await fetchApplicationsList(networkId);

      return JSON.stringify({ applications: response });
    } catch (err: any) {
      return rejectWithValue({ error: err.message });
    }
  }
);

const applicationsSlice = createSlice({
  name: 'applications',
  initialState: initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchApplicationsListAction.fulfilled, (state, action) => {
      // both `state` and `action` are now correctly typed
      // based on the slice state and the `pending` action creator
      const applicationData = JSON.parse(action.payload);
      state.applications = applicationData?.applications.map((applications: any) => {
        return {
          ...applications,
          id: applications?.applicationId,
        };
      });
      //state.applications = applicationData.applications;
      state.loading = false;
    });
    builder.addCase(fetchApplicationsListAction.pending, (state) => {
      // both `state` and `action` are now correctly typed
      // based on the slice state and the `pending` action creator
      state.loading = true;
    });
    builder.addCase(fetchApplicationsListAction.rejected, (state, action) => {
      // both `state` and `action` are now correctly typed
      // based on the slice state and the `pending` action creator
      state.error = action.error.message;
      state.loading = false;
    });
  },
  reducers: {},
});

// Export actions
//export const { searchProvider } = providerSlice.actions;

// Export reducer
export default applicationsSlice.reducer;
