import useAuth from 'hooks/useAuth';
import {
  Navigate,
  Outlet,
  useLocation,
  useSearchParams,
} from 'react-router-dom';

interface PrivateRouteProps {
  children: React.ReactElement;
  redirectTo?: string;
}

const DEFAULT_REDIRECT_PATH = '/sign-in';

export default function PrivateRoute({
  children,
  redirectTo = DEFAULT_REDIRECT_PATH,
}: PrivateRouteProps) {
  const { currentUser } = useAuth();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  return currentUser?.uid ? (
    children
  ) : (
    <Navigate
      to={redirectTo}
      state={{ from: `${location.pathname}?${searchParams.toString()}` }}
    />
  );
}

export function PrivateOutlet({
  redirectTo = DEFAULT_REDIRECT_PATH,
}: {
  redirectTo?: string;
}) {
  const { currentUser } = useAuth();
  const location = useLocation();
  return currentUser?.uid ? (
    <Outlet />
  ) : (
    <Navigate to={redirectTo} state={{ from: location.pathname }} />
  );
}
