import useAuth from 'hooks/useAuth';
import {
  Navigate,
  Outlet,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import { redirects } from './routePaths';


interface NetworkRouteProps {
  children: React.ReactElement;
  redirectTo?: string;
}

const DEFAULT_REDIRECT_PATH = redirects.signIn;

export default function NetworkRoute({
  children,
  redirectTo = DEFAULT_REDIRECT_PATH,
}: NetworkRouteProps) {
  const { currentUser } = useAuth();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  
  const isNamedUser = ():boolean => (currentUser?.uid && !currentUser.isAnonymous) ? true : false;
  const isNamed = (): boolean => {
    //console.log("NetworkRoute Check: ", currentUser);
    return isNamedUser();
  }
  const isNetworkUser = isNamed;
  
//  return currentUser?.uid ? (
 return isNetworkUser() ? (children) : (
    <Navigate
      to={redirectTo}
      state={{ from: `${location.pathname}?${searchParams.toString()}` }}
    />
  );
}

export function NetworkOutlet({
  redirectTo = DEFAULT_REDIRECT_PATH,
}: {
  redirectTo?: string;
}) {
  const { currentUser } = useAuth();
  const location = useLocation();
  return currentUser?.uid ? (
    <Outlet />
  ) : (
    <Navigate to={redirectTo} state={{ from: location.pathname }} />
  );
}
