// Initializes Firebase
import { Provider, ErrorBoundary } from '@rollbar/react';
import { Provider as ReduxProvider } from 'react-redux';
import { getRollbarConfig } from './providers/rollbar/config';
import ApplicationProvider from 'providers/ApplicationProvider';
import AppRoutes from 'routes';
import { persistor, store } from 'reduxApp/store';
import { PersistGate } from 'redux-persist/integration/react';
import './api/firebase';
import LoadingSpinner from 'components/LoadingSpinner';

export const App = () => (
  <ReduxProvider store={store}>
    <Provider config={getRollbarConfig()}>
      <PersistGate loading={<LoadingSpinner />} persistor={persistor}>
        <ErrorBoundary>
          <ApplicationProvider>
            <AppRoutes />
          </ApplicationProvider>
        </ErrorBoundary>
      </PersistGate>
    </Provider>
  </ReduxProvider>
);
