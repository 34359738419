import { Expose, Type } from 'class-transformer';
import { Coordinates } from './Coodinates';

export class Provider {
  constructor(obj: object = {}) {
    Object.assign(this, obj);
  }

  // type: uuid
  @Expose({ name: 'id' })
  public id?: string;

  @Expose({ name: 'school_id' })
  public schoolId?: string ;

  @Expose({ name: 'type' })
  public type: string = '';

  @Expose({ name: 'external_school_id' })
  public externalSchoolId?: string;

  @Expose({ name: 'name' })
  public name: string = '';

  @Expose({ name: 'slug' })
  public slug: string = '';

  @Expose({ name: 'program_type' })
  public programType: string = '';

  @Expose({ name: 'location_state' })
  public locationState: string = '';

  @Expose({ name: 'has_availability' })
  public hasAvailability?: boolean;

  @Expose({ name: 'available_spots' })
  public availableSpots: number = 0;

  @Expose({ name: 'license_number' })
  public licenseNumber?: string; 

  @Expose({ name: 'background_check' })
  public backgroundCheck?: boolean;

  @Expose({ name: 'age_months_min' })
  public ageMonthsMin?: number;

  @Expose({ name: 'age_months_max' })
  public ageMonthsMax?: number;

  @Expose({ name: 'hours_start' })
  public hoursStart?: string;

  @Expose({ name: 'hours_end' })
  public hoursEnd?: string;

    @Expose({ name: 'days_open' })
  public daysOpen?: Array<string> = [];

  @Expose({ name: 'pay_schedules' })
  public paySchedules?: Array<string> = [];

  @Expose({ name: 'meals_provided_per_day' })
  public mealsProvidedPerDay?: Array<string> = [];

  @Expose({ name: 'snacks_provided_per_day' })
  public snacksProvidedPerDay?: number;

  @Expose({ name: 'potty_training_required' })
  public pottyTrainingRequired?: boolean;

  @Expose({ name: 'subsidized_care_accepted' })
  public subsidizedCareAccepted?: boolean;

  @Expose({ name: 'spoken_languages' })
  public spokenLanguages?: Array<string> = [];

  @Expose({ name: 'location_address_line_1' })
  public locationAddressLine1?: string;

  @Expose({ name: 'location_address_line_2' })
  public locationAddressLine2?: string;

  @Expose({ name: 'location_city' })
  public locationCity?: string;

  @Expose({ name: 'location_postalcode' })
  public locationPostalcode?: string;

  @Expose({ name: 'cost_range_monthly'})
  public costRangeMonthly?: string;

  @Expose({ name: 'age_range_served'})
  public ageRangeServed?: string;

  @Type(() => Coordinates)
  @Expose({ name: 'coordinates' })
  public coordinates?: Coordinates = new Coordinates();
}
