export function getErrorMessage(
  error: unknown,
  fallBackMessage: string = 'Unknown Error'
) {
  let errorObject: Error = {
    message: fallBackMessage,
    name: fallBackMessage,
  };
  if (error instanceof Error) {
    errorObject = error;
  }

  return errorObject;
}
