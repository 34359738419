// users.service.ts
import http from './httpService/http';
import { paths } from './paths';
import { Providers, SearchProviders } from './dataObjects/Providers';
import { instanceToPlain, plainToInstance } from 'class-transformer';

const providerPath = paths.list.providers;

export const searchProviders = async (searchProvider: SearchProviders): Promise<Providers> => {
  const objArr = instanceToPlain(new SearchProviders(searchProvider));
  const response = await http.post<object, any>(providerPath(), objArr as object[]);
  return plainToInstance(Providers, response.data as {}) as Providers;
};

