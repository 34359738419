import childstepicon from 'assets/images/childstepicon.svg';
import stepIcon from 'assets/images/stepicon.svg';
import stepscheduleicon from 'assets/images/stepscheduleicon.svg';
import stepfavicon from 'assets/images/stepfavicon.svg';

import NEW_ICON from '../components/Grid/assets/status_new.svg';
import AWAITING_NETWORK_ICON from '../components/Grid/assets/status-awaiting_network_action.svg';
import AWAITING_APPLICANT_ICON from '../components/Grid/assets/status-awaiting_applicant.svg';
import WAITLISTED_ICON from '../components/Grid/assets/status-waitlisted.svg';
import IN_REVIEW_ICON from '../components/Grid/assets/status-in_review.svg';
import CANCELLED_ICON from '../components/Grid/assets/status-cancelled.svg';
import COMPLETE_ICON from '../components/Grid/assets/status-complete.svg';
import INCOMPLETE_DRAFT_ICON from '../components/Grid/assets/status-draft.svg';

/** Top navbar constants value is the path and lable shows menu on navbar*/
const TOP_MENU_ITEMS = [{ value: 'organization', label: 'Applications' }];
const PROFILE_IMAGE =
  'https://images.unsplash.com/photo-1493666438817-866a91353ca9?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9';

/** constants for modal logout confirmation */
const M_TITLE_CONFIRM = 'Confirm';
const M_BODY_TXT_LOGOUT = 'Are you sure you want to Logout?';
const M_BTN_PRIMARY = 'Confirm';
const M_BTN_SECONDARY = 'Cancel';

const WELCOME_STEPS = [
  {
    title: 'Create Profile',
    description: 'About your household',
    step: 1,
    redirectPath: 'parent/create-profile',
    isComplete: false,
    stepIcon: stepIcon,
    isActive: true,
  },
  {
    title: 'Child Information',
    description: 'About your child(ren)',
    step: 2,
    redirectPath: 'parent/child-information',
    isComplete: false,
    stepIcon: childstepicon,
    isActive: false,
  },
  {
    title: 'Child Care Schedule',
    description: 'About your schedule',
    step: 3,
    redirectPath: 'parent/child-care-schedule',
    isComplete: false,
    stepIcon: stepscheduleicon,
    isActive: false,
  },
  {
    title: 'Provider Preferences',
    description: 'Specify your preferred programs',
    step: 4,
    redirectPath: 'parent/providers',
    isComplete: false,
    stepIcon: stepfavicon,
    isActive: false,
  },
];

const WELCOME_MSG = 'Complete the following application for help finding or paying for child care.';

/** Select Options */
const GENDER_OPTIONS = [
  { value: 'Male', label: 'Male' },
  { value: 'Female', label: 'Female' },
  { value: 'Prefer not to answer', label: 'Prefer not to answer' },
];

const RACES_OPTIONS = [
  { value: 'americanIndianAlaskaNative', label: 'American Indian or Alaska Native' },
  { value: 'asian', label: 'Asian' },
  { value: 'blackAfricanAmerican', label: 'Black or African Descent' },
  { value: 'nativeHawaiianPacificIslander', label: 'Native Hawaiian or other Pacific Islander' },
  { value: 'white', label: 'White' },
];

const ETHNICITY_OPTIONS = [{ value: 'hispanic', label: 'Hispanic/Latino' }];

const TIME_OPTIONS = [
  { value: '00:00', label: '12:00am' },
  { value: '00:30', label: '12:30am' },
  { value: '01:00', label: '01:00am' },
  { value: '01:30', label: '01:30am' },
  { value: '02:00', label: '02:00am' },
  { value: '02:30', label: '02:30am' },
  { value: '03:00', label: '03:00am' },
  { value: '03:30', label: '03:30am' },
  { value: '04:00', label: '04:00am' },
  { value: '04:30', label: '04:30am' },
  { value: '05:00', label: '05:00am' },
  { value: '05:30', label: '05:30am' },
  { value: '06:00', label: '06:00am' },
  { value: '06:30', label: '06:30am' },
  { value: '07:00', label: '07:00am' },
  { value: '07:30', label: '07:30am' },
  { value: '08:00', label: '08:00am' },
  { value: '08:30', label: '08:30am' },
  { value: '09:00', label: '09:00am' },
  { value: '09:30', label: '09:30am' },
  { value: '10:00', label: '10:00am' },
  { value: '10:30', label: '10:30am' },
  { value: '11:00', label: '11:00am' },
  { value: '11:30', label: '11:30am' },
  { value: '12:00', label: '12:00pm' },
  { value: '12:30', label: '12:30pm' },
  { value: '13:00', label: '01:00pm' },
  { value: '13:30', label: '01:30pm' },
  { value: '14:00', label: '02:00pm' },
  { value: '14:30', label: '02:30pm' },
  { value: '15:00', label: '03:00pm' },
  { value: '15:30', label: '03:30pm' },
  { value: '16:00', label: '04:00pm' },
  { value: '16:30', label: '04:30pm' },
  { value: '17:00', label: '05:00pm' },
  { value: '17:30', label: '05:30pm' },
  { value: '18:00', label: '06:00pm' },
  { value: '18:30', label: '06:30pm' },
  { value: '19:00', label: '07:00pm' },
  { value: '19:30', label: '07:30pm' },
  { value: '20:00', label: '08:00pm' },
  { value: '20:30', label: '08:30pm' },
  { value: '21:00', label: '09:00pm' },
  { value: '21:30', label: '09:30pm' },
  { value: '22:00', label: '10:00pm' },
  { value: '22:30', label: '10:30pm' },
  { value: '23:00', label: '11:00pm' },
  { value: '23:30', label: '11:30pm' },
];
const APPLICATION_STATUS_OPTIONS = [
  { value: 'NEW', label: 'New' },
  { value: 'IN_REVIEW', label: 'In Review' },
  { value: 'AWAITING_NETWORK', label: 'Awaiting Network' },
  { value: 'AWAITING_APPLICANT', label: 'Awaiting Application' },
  { value: 'COMPLETE', label: 'Complete' },
  { value: 'CANCELLED', label: 'Cancelled' },
  { value: 'WAITLISTED', label: 'Waitlisted' },
  { value: 'DRAFT', label: 'Draft' },
];

//icon for application status
const APPLICATION_STATUS_STYLE = {
  NEW: {
    icon: NEW_ICON,
    style: { color: '#484848', backgroundColor: '#E2F5EA' },
    label: 'New',
  },
  AWAITING_NETWORK: {
    icon: AWAITING_NETWORK_ICON,
    style: { color: '#484848', backgroundColor: '#F6EAF6' },
    label: 'Awaiting Network',
  },
  AWAITING_APPLICANT: {
    icon: AWAITING_APPLICANT_ICON,
    style: { color: '#484848', backgroundColor: '#FFF2D6' },
    label: 'Awaiting Applicant',
  },
  WAITLISTED: {
    icon: WAITLISTED_ICON,
    style: { color: '#484848', backgroundColor: '#FBD1CE' },
    label: 'Waitlisted',
  },
  IN_REVIEW: {
    icon: IN_REVIEW_ICON,
    style: { color: '#484848', backgroundColor: '#ECEEFC' },
    label: 'In Review',
  },
  CANCELLED: {
    icon: CANCELLED_ICON,
    style: { color: '#484848', backgroundColor: '#F9E6E6' },
    label: 'Cancelled',
  },
  COMPLETE: {
    icon: COMPLETE_ICON,
    style: { color: '#484848', backgroundColor: '#EAF5FE' },
    label: 'Complete',
  },
  INCOMPLETE_DRAFT: {
    icon: INCOMPLETE_DRAFT_ICON,
    style: {
      color: '#484848',
      backgroundColor: '#FFF',
      border: '1px solid',
      borderRadius: '16px',
    },
    label: 'Draft',
  },
};

const DEFAULT_SCHEDULE_DROP_OFF = { value: '', label: 'Drop off' };

const DEFAULT_SCHEDULE_PICK_UP = { value: '', label: 'Pick up' };

const STATE_LIST = [
  {
    label: 'Alabama',
    value: 'AL',
  },
  {
    label: 'Alaska',
    value: 'AK',
  },
  {
    label: 'American Samoa',
    value: 'AS',
  },
  {
    label: 'Arizona',
    value: 'AZ',
  },
  {
    label: 'Arkansas',
    value: 'AR',
  },
  {
    label: 'California',
    value: 'CA',
  },
  {
    label: 'Colorado',
    value: 'CO',
  },
  {
    label: 'Connecticut',
    value: 'CT',
  },
  {
    label: 'Delaware',
    value: 'DE',
  },
  {
    label: 'District Of Columbia',
    value: 'DC',
  },
  {
    label: 'Federated States Of Micronesia',
    value: 'FM',
  },
  {
    label: 'Florida',
    value: 'FL',
  },
  {
    label: 'Georgia',
    value: 'GA',
  },
  {
    label: 'Guam',
    value: 'GU',
  },
  {
    label: 'Hawaii',
    value: 'HI',
  },
  {
    label: 'Idaho',
    value: 'ID',
  },
  {
    label: 'Illinois',
    value: 'IL',
  },
  {
    label: 'Indiana',
    value: 'IN',
  },
  {
    label: 'Iowa',
    value: 'IA',
  },
  {
    label: 'Kansas',
    value: 'KS',
  },
  {
    label: 'Kentucky',
    value: 'KY',
  },
  {
    label: 'Louisiana',
    value: 'LA',
  },
  {
    label: 'Maine',
    value: 'ME',
  },
  {
    label: 'Marshall Islands',
    value: 'MH',
  },
  {
    label: 'Maryland',
    value: 'MD',
  },
  {
    label: 'Massachusetts',
    value: 'MA',
  },
  {
    label: 'Michigan',
    value: 'MI',
  },
  {
    label: 'Minnesota',
    value: 'MN',
  },
  {
    label: 'Mississippi',
    value: 'MS',
  },
  {
    label: 'Missouri',
    value: 'MO',
  },
  {
    label: 'Montana',
    value: 'MT',
  },
  {
    label: 'Nebraska',
    value: 'NE',
  },
  {
    label: 'Nevada',
    value: 'NV',
  },
  {
    label: 'New Hampshire',
    value: 'NH',
  },
  {
    label: 'New Jersey',
    value: 'NJ',
  },
  {
    label: 'New Mexico',
    value: 'NM',
  },
  {
    label: 'New York',
    value: 'NY',
  },
  {
    label: 'North Carolina',
    value: 'NC',
  },
  {
    label: 'North Dakota',
    value: 'ND',
  },
  {
    label: 'Northern Mariana Islands',
    value: 'MP',
  },
  {
    label: 'Ohio',
    value: 'OH',
  },
  {
    label: 'Oklahoma',
    value: 'OK',
  },
  {
    label: 'Oregon',
    value: 'OR',
  },
  {
    label: 'Palau',
    value: 'PW',
  },
  {
    label: 'Pennsylvania',
    value: 'PA',
  },
  {
    label: 'Puerto Rico',
    value: 'PR',
  },
  {
    label: 'Rhode Island',
    value: 'RI',
  },
  {
    label: 'South Carolina',
    value: 'SC',
  },
  {
    label: 'South Dakota',
    value: 'SD',
  },
  {
    label: 'Tennessee',
    value: 'TN',
  },
  {
    label: 'Texas',
    value: 'TX',
  },
  {
    label: 'Utah',
    value: 'UT',
  },
  {
    label: 'Vermont',
    value: 'VT',
  },
  {
    label: 'Virgin Islands',
    value: 'VI',
  },
  {
    label: 'Virginia',
    value: 'VA',
  },
  {
    label: 'Washington',
    value: 'WA',
  },
  {
    label: 'West Virginia',
    value: 'WV',
  },
  {
    label: 'Wisconsin',
    value: 'WI',
  },
  {
    label: 'Wyoming',
    value: 'WY',
  },
];

const WEEKDAYS = [
  { key: 'MON', value: 'monday' },
  { key: 'TUE', value: 'tuesday' },
  { key: 'WED', value: 'wednesday' },
  { key: 'THU', value: 'thursday' },
  { key: 'FRI', value: 'friday' },
  { key: 'SAT', value: 'saturday' },
  { key: 'SUN', value: 'sunday' },
];

const SCHELD_WEEK_COLLECTION = {
  mondayDropoff: 'mondayDropoff',
  mondayPickup: 'mondayPickup',
  tuesdayDropoff: 'tuesdayDropoff',
  tuesdayPickup: 'tuesdayPickup',
  wednesdayDropoff: 'wednesdayDropoff',
  wednesdayPickup: 'wednesdayPickup',
  thursdayDropoff: 'thursdayDropoff',
  thursdayPickup: 'thursdayPickup',
  fridayDropoff: 'fridayDropoff',
  fridayPickup: 'fridayPickup',
  saturdayDropoff: 'saturdayDropoff',
  saturdayPickup: 'saturdayPickup',
  sundayDropoff: 'sundayDropoff',
  sundayPickup: 'sundayPickup',
};

const CASE_MANAGERS = [
  { value: 'ElsV86PIP7e9VuNOiPrHBlPVxew1', label: 'Deburn Nathan' },
  { value: 'CAcC57EhP1hhbh8uhfElYHDd0hI3', label: 'Vivek Singh' },
  { value: 'UAD5RHnBIGdGsYic5QntZR5bwHF2', label: 'Rajiv Bagade' },
];

export const constant = {
  Header: {
    TOP_MENU_ITEMS,
    PROFILE_IMAGE,
  },
  Modal: {
    M_BTN_PRIMARY,
    M_BTN_SECONDARY,
    M_BODY_TXT_LOGOUT,
    M_TITLE_CONFIRM,
  },
  WELCOME_STEPS,
  WELCOME_MSG,
  GENDER_OPTIONS,
  RACES_OPTIONS,
  ETHNICITY_OPTIONS,
  TIME_OPTIONS,
  APPLICATION_STATUS_OPTIONS,
  DEFAULT_SCHEDULE_DROP_OFF,
  DEFAULT_SCHEDULE_PICK_UP,
  STATE_LIST,
  WEEKDAYS,
  SCHELD_WEEK_COLLECTION,
  CASE_MANAGERS,
  APPLICATION_STATUS_STYLE,
};
