import { Expose, Type } from 'class-transformer';
import { Provider } from './Provider';

export class ProviderResult {
  constructor(obj: object = {}) {
    Object.assign(this, obj);
  }

  @Expose({ name: 'miles' })
  public miles: number = 0;

  @Type(() => Provider)
  @Expose({ name: 'provider' })
  public provider: Provider = new Provider();
}
